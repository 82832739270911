import { Chip } from '@mui/material';
import clsx from 'clsx';
import { ReportingEventDTO, ReportingStateEnum } from '../../reportingApi.types';
import classes from './StatusChip.module.css';

export const StatusChip = ({ reportingState }: ReportingEventDTO) => {
  return (
    <Chip
      variant="outlined"
      size="small"
      label={reportingState}
      className={clsx(
        classes.chip,
        classes[(reportingState || ReportingStateEnum.Pending).toLowerCase()],
      )}
    />
  );
};
